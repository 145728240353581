import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { DelButton, DialogMessage } from './AlertDialog.styled';

const AlertDialog = (props) => {
  const { handleClose, handleAlertFunction, dialogOpenState, dialogMessage, imageId } = props;
  return (
    <Dialog
      open={dialogOpenState}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title"> </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ color: '#498205', fontSize: '16px', fontWeight: 'bold', display: 'flex' }}>
          <ReportProblemOutlinedIcon style={{ color: 'gold', fontSize: '3rem' }} />
            <DialogMessage>
            {dialogMessage}
            <br />
            {imageId}
            </DialogMessage>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>
          <DelButton>
            No
          </DelButton>
        </Button>
        <Button onClick={() => handleAlertFunction()} autoFocus>
          <DelButton>
            Yes
          </DelButton>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;

AlertDialog.propTypes = {
  dialogMessage: PropTypes.string,
  dialogOpenState: PropTypes.bool,
  handleAlertFunction: PropTypes.func,
  handleClose: PropTypes.func,
  imageId: PropTypes.string
};
