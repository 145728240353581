// Use this file for all section constants which are shared across multiple section.
// Indivisual section constants should be in it's own folder

export const AutomaticActivationFireSuppression = {
    DISCARD: 'Discard'
};

export const commonConstants = {
    Discard: 'Discard',
    Perform: 'Perform',
    Yes: 'Yes',
    No: 'No',
    YES: 'YES',
    NO: 'NO',
    Pass: 'Pass',
    Fail: 'Fail',
    Open: 'Open',
    Closed: 'Closed',
    Quick: 'Quick',
    Sticking: 'Sticking',
    Not_Known_or_Visible: 'Not Known or Visible',
    DEFECT_FOUND_EQUIPMENT_ISOLATED: 'Defect Found Equipment Isolated (DDES)',
    DEFECT_FOUND_EQUIPMENT_ONLINE: 'Defect Found Equipment Online (DDEO)'
};

export const FunctionTestType = {
    DRY_TEST: 'Dry Test'
};

export const Setpoint = {
    GAUGE_READING: 'Gauge Reading',
    TEST_MODULE_READING: 'Test Module Reading',
    SYSTEM1_READING: 'System 1 Reading',
    HMI_READING: 'HMI Reading',
    HMI_READING_EU: 'HMI Reading (HMI EU)',
    TRANSMITTER_READING: 'Transmitter Reading',
    ANALYZER_READING: 'Analyzer Reading',
    FLOW_TX_READING: 'Flow Tx Reading',
    INDEPENDENT_FLOW_READING: 'Independent Level / Simulator Reading (%)',
    HMI_READING_PRECENT: 'HMI Reading (%)',
    HIGH_HIGH_SETPOINT_EU: 'High High Setpoint (EU)',
    HIGH_HIGH_SETPOINT: 'High High Setpoint',
    LOW_LOW_SETPOINT_EU: 'Low Low Setpoint (EU)',
    LOW_LOW_SETPOINT: 'Low Low Setpoint',
    HIGH_HIGH_SETPOINT_COMMENT: 'High High Setpoint Comment',
    HIGH_SETPOINT: 'High Setpoint',
    HIGH_SETPOINT_EU: 'High Setpoint (EU)',
    HIGH_SETPOINT_COMMENT: 'High Setpoint Comment',
    LOW_SETPOINT_EU: 'Low Setpoint (EU)',
    LOW_SETPOINT: 'Low Setpoint',
    LOW_LOW_SETPOINT_COMMENT: 'Low Low Setpoint Comment',
    LOW_SETPOINT_COMMENT: 'Low Setpoint Comment',
    TEST_DEVICE_READING: 'Test Device Reading',
    VALUE_TO_APPLY: 'Value to Apply'
};

export const EngineeringUnit = {
    HMI_ENGINEERING_UNIT_EU: 'HMI Engineering Unit (HMI EU)',
    INSTRUMENT_ENGINEERING_UNIT_EU: 'Instrument Engineering Unit (EU)'
};

export const FailureMode = {
    FAILURE_MODE_LABEL: 'Remark: Verify that fault alarm is displayed on HMI and then record the corresponding mA value.'
};

export const FailedComponent = {
    SEARCH_FAILED_COMPONENTS: 'Search Failed Components'
};

export const Result = {
    RESULT_PASS: 'PASS',
    RESULT_FAIL: 'FAIL',
    RESULT_NA: 'NA'
};

export const Label = {
    SMOKE_CONFIRMATION_LABEL: 'Confirm that smoke is injected to the furthest accessible sample hole along the pipe network.',
    HMI_STATUS_LABEL: 'HMI Status'
};

export const Placeholder = {
    HMI_STATUS_PLACEHOLDER: 'Select HMI Status',
    SELECT_YES_OR_NO: 'Select YES or NO',
    SELECT_PHYSICAL_POSITION: 'Select Physical Position',
    SELECT_HMI_POSITION: 'Select HMI Position',
    SELECT_TEST_RESULT: 'Select Test Result',
    SELECT: 'Select',

};

export const errorMessageMap = {
    'M6-10': 'Enter Value in range of 1-8',
    default: 'Enter Value in range of 1-10'
};

export const AlarmTestResult = {
    DISCARD: 'DISCARD',
    PERFORM: 'PERFORM'
};

export const SafetyFunctionDescriptionData = {
    F836IplelementSolenoid: 'control valve trip via solenoid.',
    F837IplelementIppositioner: 'control valve position regulation.',
    F838IplelementPositionTransmitter: 'control valve position feedback.',
    F839IplelementLimitSwitch: 'limit switch activation.'
};
export const safetyFunctionDescriptionDataP6232 = {
    F836IplelementSolenoid: 'louver/damper trip via solenoid.',
    F837IplelementIppositioner: 'louver/damper position regulation.',
    F838IplelementPositionTransmitter: 'louver/damper position feedback.',
    F839IplelementLimitSwitch: 'limit switch activation.'
};

export const controlValveToLouverDamper = (formName) => {
    if (formName === 'P6-23.2') return 'Louver/Damper';
    return 'Control Valve';
};

export const controlValveToLouverDamper2 = (formName) => {
    if (formName === 'P6-23.2') return 'louver/damper';
    return 'control valve';
};

export const valveToLouverDamper = (formName) => {
    if (formName === 'P6-23.2') return 'Louver/Damper';
    return 'Valve';
};
export const valveToLouverDamper2 = (formName) => {
    if (formName === 'P6-23.2') return 'louver/damper';
    return 'valve';
};

export const asFoundTestLabel = (formName) => {
    if (formName === 'S7-3') return 'As-Found Test (Primary Element)';
    return 'As-Found Test';
};

export const asLeftTestLabel = (formName) => {
    if (formName === 'S7-3') return 'As-Left Test (Primary Element)';
    return 'As-Left Test';
};

export const TxAsFoundLabel = {
    Tx_As_Found: 'Tx As Found',
    Alternative_Tx: 'Alternative Tx',
    Sight_Glass: 'Sight Glass'
};

export const classOptions = [
    { name: Placeholder.SELECT, value: '' },
    { name: 'Class 1', value: 'Class 1' },
    { name: 'Class 2', value: 'Class 2' },
    { name: 'Class 3', value: 'Class 3' },
    { name: commonConstants.Not_Known_or_Visible, value: commonConstants.Not_Known_or_Visible }
];
export const gasGroupOptions = [
    { name: Placeholder.SELECT, value: '' },
    { name: 'I', value: 'I' },
    { name: 'IIA', value: 'IIA' },
    { name: 'IIB', value: 'IIB' },
    { name: 'IIC', value: 'IIC' },
    { name: commonConstants.Not_Known_or_Visible, value: commonConstants.Not_Known_or_Visible }
];
export const divisionOptions = [
    { name: Placeholder.SELECT, value: '' },
    { name: 'Div 1', value: 'Div 1' },
    { name: 'Div 2', value: 'Div 2' },
    { name: commonConstants.Not_Known_or_Visible, value: commonConstants.Not_Known_or_Visible }
];

export const temperaturClassOptions = [
    { name: Placeholder.SELECT, value: '' },
    { name: 'T1', value: 'T1' },
    { name: 'T2', value: 'T2' },
    { name: 'T3', value: 'T3' },
    { name: 'T4', value: 'T4' },
    { name: 'T5', value: 'T5' },
    { name: 'T6', value: 'T6' },
    { name: commonConstants.Not_Known_or_Visible, value: commonConstants.Not_Known_or_Visible }
];

export const zoneOptions = [
    { name: Placeholder.SELECT, value: '' },
    { name: 'Zone 0', value: 'Zone 0' },
    { name: 'Zone 1', value: 'Zone 1' },
    { name: 'Zone 2', value: 'Zone 2' },
    { name: commonConstants.Not_Known_or_Visible, value: commonConstants.Not_Known_or_Visible }
];
export const equipmentProtectionLevelOptions = [
    { name: Placeholder.SELECT, value: '' },
    { name: 'Ga', value: 'Ga' },
    { name: 'Gb', value: 'Gb' },
    { name: 'Gc', value: 'Gc' },
    { name: commonConstants.Not_Known_or_Visible, value: commonConstants.Not_Known_or_Visible }
];
